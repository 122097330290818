import React from 'react';

const IconLoader = () => (

  <svg xmlns="http://www.w3.org/2000/svg" width="261.021" height="298.536" viewBox="0 0 261.021 298.536">
      <g transform="translate(-238.695 -107.379)">
        <text transform="translate(293 324)" fill="#00b7f9" font-size="200" font-family="SegoeUI-Semibold, Segoe UI" font-weight="600">
            <tspan x="0" y="0">R</tspan>
        </text>
        <path d="M435.651,850.174l126.064-68.158V637.388L437.036,563.135,310.695,632.124V776.474Z" transform="translate(-67 -450)" fill="none" stroke="#00b7f9" stroke-width="10"/>
      </g>
  </svg>


);

export default IconLoader;
